<template>
  <van-tabbar
    route
    v-model="active"
    active-color="#49A9F8"
    inactive-color="#D6DAE3"
  >
    <van-tabbar-item
      :to="item.name"
      @click="tabIndex(index)"
      v-for="(item, index) in tabbars"
      :key="'tabbar' + index"
    >
      <span>{{ item.title }}</span>

      <template #icon="props">
        <img :src="props.active ? item.active : item.normal" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: "indexTab",
  data() {
    return {
      active: 0, //默认选中tab
      tabbars: [
        {
          name: "/home",
          title: "首页",
          normal: require("@/assets/bar_home.png"),
          active: require("@/assets/bar_home1.png"),
        },

        {
          name: "/mine",
          title: "我的",
          normal: require("@/assets/bar_user.png"),
          active: require("@/assets/bar_user1.png"),
        },
      ],
    };
  },
  mounted() {
    // 如有缓存，刷新页面时还进入该页面
    let index = sessionStorage.getItem("tabIndex");
    if (index) {
      this.tabIndex(index);
    }
  },
  methods: {
    tabIndex(index) {
      index = Number(index);
      this.active = index;
      // 记录当前tab页
      sessionStorage.setItem("tabIndex", index);
      let val = this.tabbars[index].name;
      this.$router.push(val);
    },
  },
};
</script>
