// 按需全局引入 vant组件
import Vue from "vue";
import {
  Icon,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  Swipe,
  SwipeItem,
  Card,
  Tag,
  Button,
  SubmitBar,
  Dialog,
  Popup,
  Toast,
  List,
  Cell,
} from "vant";
Vue.use(Icon);
Vue.use(Tab).use(Tabs);
Vue.use(Tabbar).use(TabbarItem);
Vue.use(Swipe).use(SwipeItem);
Vue.use(Card);
Vue.use(Tag);
Vue.use(Button);
Vue.use(SubmitBar);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(List);
