<template>
  <div class="paypage">
    <div class="pay_t">
      <!-- <van-icon class="weixin_pay_icon" name="wechat-pay" color="" /> -->
      <!-- <span v-if="orderResult">支付成功！</span>
      <span v-else>支付失败！</span> -->
      <span class="order_id">订单编号：{{ orderId }}</span>
      <!-- <van-icon name="checked" />
      <van-icon name="clear" /> -->
      <van-icon :name="iconName" />
      <span class="pay_result"> {{ payResult }}</span>
    </div>
    <van-button class="gohome" color="#f8cf39" size="small" @click="goToHome"
      >返回首页</van-button
    >
  </div>
</template>

<script>
import https from "@/api/https";
let _that;
export default {
  name: "Payment",
  data() {
    return {
      orderId: "",
      orderResult: null,
      payResult: "支付结果查询中...",
      iconName: "",
    };
  },
  created() {
    _that = this;
    this.orderId = localStorage.getItem("orderId");
    this.setIntervalPayResult();
  },
  methods: {
    goToHome() {
      this.$router.push({ path: "/home" });
    },
    setIntervalPayResult() {
      let count = 0;
      let _result = {
        message: "支付失败",
        success: false,
      };
      let timer = setInterval(function () {
        if (count >= 10) {
          _that.showPayResult(_result);
          clearInterval(timer);
          return;
        }
        https
          .fetchGet("/backend/order/pay_result", { orderId: _that.orderId })
          .then((res) => {
            if (res.status == 200) {
              if (res.data.data && res.data.data.success) {
                _result = res.data.data;
                _that.showPayResult(_result);
                clearInterval(timer);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            _that.showPayResult(_result);
            clearInterval(timer);
          });
        count++;
      }, 1000);
    },
    showPayResult(result) {
      _that.orderResult = result;
      switch (result.success) {
        case true:
          this.iconName = "checked";
          // _that.payResult="支付成功！";
          break;
        case false:
          this.iconName = "clear";
          // _that.payResult="支付失败！";
          break;
        default:
          break;
      }
      _that.payResult = result.message;
    },
    clearData() {},
  },
};
</script>

<style lang="less" scoped>
.paypage {
  height: 100%;
  background: #f7f7f7;
  .pay_t {
    padding-top: 50px;
    text-align: center;
    .order_id {
      display: block;
      margin-bottom: 12px;
    }
  }
  .gohome {
    display: block;
    margin: 20px auto 0;
  }
}
</style>
