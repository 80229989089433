import axios from "axios";
// import store from './store' //vuex
// import router from './router' //路由

axios.defaults.timeout = 5000; //响应时间
axios.defaults.baseURL = "https://www.taiyiai11.com"; //配置接口地址

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  (config) => {
    // console.log(config.data);
    //在发送请求之前做某件事
    //携带请求头
    let token = window.localStorage.getItem("accessToken");
    // console.log("token:"+token);
    //下面两种方式都行
    // config.headers.accessToken = token;
    config.headers["accessToken"] = token;

    return config;
  },
  (error) => {
    console.log("错误的传参");
    return Promise.reject(error);
  }
);

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      const data = res.data;
      //对响应数据做些事
      if (!res.data.success) {
        return Promise.resolve(res);
      }
      // return res;
      return data;

      // if (data.code === 400){
      //     //登录过期,权限不足
      //     console.warn("登陆过期");
      //     //清除token
      //     store.commit('setToken','')
      //     window.localStorage.removeItem('token')
      //     //跳转登录
      //     router.replace({
      //         path:"/login"
      //     })
      // }
    } else {
      console.log("666666");
      alert(res.data.msg);
    }
  },
  (error) => {
    console.log("网络异常");
    return Promise.reject(error);
  }
);

//返回一个Promise(发送post请求)
export function fetchPost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}

// 返回一个Promise(发送get请求)
export function fetchGet(url, param) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: param })
      .then(
        (response) => {
          if (response.data.status == 200) {
            resolve(response);
          } else {
            alert(response.data.msg);
          }
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        alert("网络异常");
        reject(error);
      });
  });
}

export default {
  fetchPost,
  fetchGet,
};
