<template>
  <div class="home">
    <!-- <div class="topsec">
      <span class="topsec_txt" color="#f8cf39"
        >智能柜：{{ vendingMachineId }}
      </span>
    </div> -->
    <div class="home_content">
      <van-swipe
        :autoplay="3000"
        @change="onChange"
        :loop="false"
        :height="250"
        indicator-color="#f8cf39"
      >
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image" class="swiper_img" :height="220" />
        </van-swipe-item>
      </van-swipe>
      <van-tabs
        v-model="activetab"
        title-inactive-color="#49A9F8"
        color="#49A9F8"
        title-active-color="#49A9F8"
        class="ab"
      >
        <van-tab name="a" title="柜内商品" badge="荐"> </van-tab>
        <van-tab name="b" title="更多商品"></van-tab>
      </van-tabs>
      <ul class="goods_ul" :class="cartFlag ? 'goods_ul_padding' : ''">
        <li
          class="goods_li"
          v-for="(item, index) in goodsList"
          :key="item.vendingMachineDoorId"
        >
          <div class="goods_box" data-idx="index">
            <img
              class="goods_img"
              :src="item.product.iconUrl"
              @click="goodsDetail(index)"
            />
            <!-- <img class="goods_img" :src="domain + item.product.iconUrl" @click="goodsDetail(index)" /> -->
            <!-- <van-tag color="#7232dd" class="goods_img_tag">101</van-tag> -->
            <div class="goods_title">{{ item.product.name }}</div>
            <div class="goods_info">
              <span class="goods_price">￥{{ item.product.price }}</span>
              <!-- <van-icon name="add" color="#f8cf39" class="goods_cart_icon" @click="goodscart(index)" /> -->
              <van-button
                class="goods_cart_icon"
                size="mini"
                @click="goodscart(index)"
                icon="iconbg"
              ></van-button>
            </div>
          </div>
        </li>
      </ul>
      <div class="goods_cart" v-if="cartFlag">
        <div class="goods_cart_flex">
          <van-icon class="cart_icon" name="shopping-cart-o" :badge="cartNum" />
          <span class="cart_clean" @click="cleanCart">清空</span>
          <div class="cart_total">
            <span class="cart_total_text">共计：￥{{ total.totalPrice }}</span>
            <span class="cart_total_text" v-if="total.couponPrice">
              券后价：￥{{ total.afterCouponPrice }}</span
            >
          </div>
          <van-button
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            size="small"
            @click="submitOrder"
            >提交订单</van-button
          >
        </div>
      </div>
    </div>
    <div class="mask" v-if="showMask">
      <img
        class="loading"
        src="https://p3.lefile.cn/product/adminweb/2020/09/25/b831808f-deac-4526-aa16-b3e1a3e85b0a.gif"
      />
    </div>
    <NAV />
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";
import NAV from "@/components/nav.vue";
import https from "@/api/https";
import wx from "weixin-js-sdk";
let _that;
Vue.use(Lazyload);

export default {
  name: "Home",
  components: {
    NAV,
    // [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      active: "home",
      activetab: "a",
      domain: "https://www.taiyiai11.com",
      images: [
        "https://we7.ilifeceo.cn/images/46/2019/11/j42r2ps4cA6TavQC2626tpTncv2biS.jpg",
        "https://we7.ilifeceo.cn/images/46/2020/07/R9Q64xF427SCHz432iXI74C23V3vZ7.jpg",
      ],
      goodsList: [],
      cartFlag: false,
      checkIndex: -1,
      checkProductId: "",
      cartNum: 1,
      total: {},
      showMask: false,
      showCoupon: false,
      couponPrice: -2,
    };
  },
  created() {
    _that = this;
    if (document.cookie.indexOf("userId") == -1) {
      this.userId = localStorage.getItem("userId");
    } else {
      this.userId = this.getCookie("userId");
    }
    if (document.cookie.indexOf("vendingMachineId") == -1) {
      this.vendingMachineId = localStorage.getItem("vendingMachineId");
    } else {
      this.vendingMachineId = this.getCookie("vendingMachineId");
    }
    this.getHomeData();
  },
  methods: {
    onChange(index) {
      console.log(index);
    },
    getHomeData() {
      if (this.vendingMachineId == "") {
        this.vendingMachineId = "vm0000001";
      }
      https
        .fetchGet("/backend/product/list_machine_products", {
          vendingMachineId: this.vendingMachineId,
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.goodsList = res.data.data.products;
            this.images = res.data.data.promotions;
          }
          _that.getCouponData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCouponData() {
      // let _showCouponStorage = localStorage.getItem("showCoupon");
      if (!sessionStorage.getItem("showCoupon")) {
        https
          .fetchGet("/backend/order/is_new_user", { userId: this.userId })
          .then((res) => {
            if (res.data.status == 200) {
              if (res.data.data.isNewUser == "true") {
                _that.showCoupon = true;
                _that.couponPrice = res.data.data.coupon.price;
              } else {
                // this.showCoupon =true;
                // this.couponPrice = 2;
              }
              sessionStorage.setItem("showCoupon", _that.showCoupon);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    cleanCart() {
      this.checkProductId = "";
      this.checkIndex = -1;
      this.cartFlag = false;
    },
    goodscart(index) {
      this.checkIndex = index;
      this.submitOrder();
      // let _data = this.goodsList[index];
      // if(this.checkProductId == "") {
      //   this.checkProductId = _data.product.productId;
      //   this.checkIndex = index;
      // } else if(_data.product.productId == this.checkProductId) {
      //   alert("暂不支持购买多件商品，请分批购买~");
      //   // this.cartNum++;
      //   return ;
      // } else {
      //   alert("当前商品不能加购~");
      //   return ;
      // }

      // https.fetchGet('/backend/product/user_price_detail', {userId: this.userId, productId: _data.product.productId,quantity: this.cartNum}).then((res) => {
      //   if(res.data.status == 200) {
      //     this.total = res.data.data;
      //     this.cartFlag = true;
      //   }
      // }).catch(err=>{
      //   console.log(err)
      // })
    },
    goodsDetail(index) {
      let _data = this.goodsList[index];
      let _urlData =
        "productId=" +
        _data.product.productId +
        "&vendingMachineId=" +
        _data.vendingMachineId +
        "&vendingMachineDoorId=" +
        _data.vendingMachineDoorId;
      this.$router.push({ path: "/detail?" + _urlData });
    },
    submitOrder() {
      // 判断浏览器环境
      if (!this.isWeiXin()) {
        alert("请在微信浏览器中打开~");
        return;
      }
      let __data = this.goodsList[this.checkIndex];
      let _data = {
        userId: this.userId,
        vendingMachineId: __data.vendingMachineId,
        vendingMachineDoorId: __data.vendingMachineDoorId,
        productId: __data.product.productId,
        quantity: this.cartNum,
      };
      https
        .fetchGet("/backend/order/create_and_pay", _data)
        .then((res) => {
          this.showMask = true;
          let _data = res.data.data;
          let orderId = res.data.data.orderId;
          this.orderId = res.data.data.orderId;
          localStorage.setItem("orderId", orderId);
          // alert("即将跳转微信支付");
          //函数为分装过得 (就是调用微信支付)
          this.wexinPay({
            appId: _data.appId,
            nonceStr: _data.nonceStr,
            package: _data.package,
            paySign: _data.paySign,
            signType: _data.signType,
            timeStamp: _data.timeStamp,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    wexinPay(data) {
      //获取后台传入的数据
      let appId = data.appId;
      let timestamp = data.timeStamp;
      let nonceStr = data.nonceStr;
      let signature = data.signature;
      let packages = data.package;
      let paySign = data.paySign;
      let signType = data.signType;
      //下面要发起微信支付了
      wx.config({
        // beta: true,
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      wx.ready(function () {
        wx.chooseWXPay({
          timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
          signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: paySign, // 支付签名
          success: function (res) {
            // 支付成功后的回调函数
            console.log(res);
            // 前端判断返回方式，微信团队郑重提示：不保证绝对可靠，切记！
            if (res.errMsg == "chooseWXPay:ok") {
              _that.setIntervalPayResult();
            } else if (res.errMsg == "chooseWXPay:cancel") {
              _that.showMask = false;
              alert("取消支付!");
              // setTimeout(function() {
              //     this.showMask = false;
              //     _that.$router.push({path:'/payment'});
              // }, 500)
            } else {
              _that.showMask = false;
              alert("订单支付失败~");
              // setTimeout(function() {
              //     _that.showMask = false;
              //     _that.$router.push({path:'/payment'});
              // }, 1000)
            }
          },
          cancel() {
            _that.showMask = false;
            alert("取消支付~");
            // setTimeout(function() {
            //   this.showMask = false;
            //   _that.$router.push({path:'/payment'});
            // }, 500)
          },
          fail() {
            //失败回调函数
            _that.showMask = false;
            alert("订单支付失败！");
            // setTimeout(function() {
            //     this.showMask = false;
            //     _that.$router.push({path:'/payment'});
            // }, 500)
          },
        });
      });
      wx.error(function (res) {
        _that.showMask = false;
        console.log(res);
        alert("订单支付失败，请联系客服！");
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    },
    setIntervalPayResult() {
      let count = 0;
      let _result = false;
      let timer = setInterval(function () {
        if (count >= 5) {
          clearInterval(timer);
          _that.showMask = false;
          _that.$router.push({ path: "/payment" });
          return;
        }
        https
          .fetchGet("/backend/order/pay_result", { orderId: _that.orderId })
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              if (res.data.data.success) {
                clearInterval(timer);
                _that.showMask = false;
                _that.$router.push({ path: "/payment" });
              }
            }
          })
          .catch((err) => {
            console.log(err);
            _that.showPayResult(_result);
            clearInterval(timer);
          });
        count++;
      }, 500);
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    setCookie(name, value) {
      var Days = 30;
      var exp = new Date();
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
      document.cookie =
        name + "=" + escape(value) + ";expires=" + exp.toGMTString();
    },
    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i].trim();
          // 判断这个cookie的参数名是不是我们想要的
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
    isWeiXin() {
      //判断是否微信平台
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less">
.home {
  position: relative;
  widows: 100%;
  padding-top: 58px;
  height: 100%;
  // background: #1a0808;
  background-image: url("~@/assets/background.png");
  .topsec {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 15px 50px;
    // background: #f8f8fb;
    box-sizing: border-box;
    z-index: 1000;
    .topsec_txt {
      display: block;
      padding-left: 1%;
      color: #0a0a0a89;
      // background: rgba(2, 49, 69, 0.708);
      border-radius: 3px;
      line-height: 18px;
      text-align: middle;
    }
  }

  .home_content {
    position: relative;
    height: calc(100% - 50px);
    overflow-y: auto;
    .swiper_img {
      display: block;
      width: 100%;
    }
    .goods_ul {
      &.goods_ul_padding {
        padding-bottom: 40px;
      }
      margin-top: 8px;
      .goods_li {
        display: inline-block;
        // width: 48%;
        // margin: 0 1%;
        width: 50%;
        // margin-top: 10px;
        // background: #fff;
        border-radius: 10px;
        // border-bottom: 1px solid #f7f7f7;
        .goods_box {
          position: relative;
          margin: 0 20px;
          padding: 20px 0;
          text-align: left;
          .goods_img_tag {
            position: absolute;
            top: 15px;
            left: 0;
            border-radius: 10px 0 0 0;
            height: 30px;
            font-size: 11px;
          }
          .goods_img {
            display: block;
            width: 100%;
            height: 120px;
            border-radius: 10px 10px 0 0;
          }
          .goods_title {
            padding: 6px 0;
            line-height: 18px;
            font-size: 14px;
          }
          .goods_info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .goods_price {
              flex: 1;
              font-weight: bold;
              font-size: 14px;
              color: #f00;
              .goods_cart_icon {
                height: 20px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
    .van-icon-iconbg {
      background-image: url("~@/assets/buy.png");
      height: 20px;
      width: 20px;
      background-size: 100% 100%;
      outline: none;
      border: none;
    }
    .goods_cart {
      position: fixed;
      bottom: 55px;
      left: 0;
      width: 100%;
      // height: 76px;
      height: 50px;
      background: #f1f1f1;
      .goods_cart_flex {
        height: 50px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        line-height: 50px;
        .cart_icon {
          margin-top: 5px;
          margin-right: 12px;
          font-size: 24px;
        }
        .cart_total {
          flex: 1;
          display: flex;
          flex-direction: column;
          text-align: center;
          .cart_total_text {
            flex: 1;
            line-height: 24px;
          }
        }
      }
    }
    .ab {
      margin: 0 12px;
      border: none;
      clip-path: inset(0 round 10px);
      // background: rgba(255, 255, 255, 0.7);
      filter: opacity(0.6);
    }
  }
  .coupon_pop {
    .coupon_img {
      width: 100%;
    }
  }

  .popup_dialog {
    background-color: rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 30%;
    left: 0;
    width: 100vw;
    height: 100vh;
    // .popup_title {
    //   padding-top: 10px;
    //   text-align: center;
    //   font-size: 13px;
    // }
    .coupon_img {
      display: block;
      width: 65%;
      margin: 30px auto 0;
    }
  }
  .mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    .loading {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 160px;
      height: 140px;
    }
  }
}
</style>
