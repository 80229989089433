import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css/normalize.css";
import "./plugins/vant";

import axios from "axios";
import wx from "weixin-js-sdk";
Vue.config.productionTip = false;

//Vue全局挂载axios
Vue.prototype.$http = axios;
Vue.prototype.$wx = wx;
//设置baseUrl
// axios.defaults.baseURL = "/api";
axios.defaults.baseURL = "https://www.taiyiai11.com/";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
