<template>
  <div class="detail-pages">
    <div class="detail-top">
      <div class="user-info">
        <img class="user-img" :height="40" :width="180" :src="goods.iconUrl" />
        <!-- <img class="user-img" :height="20" :width="180" :src="domain+goods.iconUrl"/> -->
        <!-- <span class="user-name">商品名称</span> -->
      </div>
      <div class="goods-price">
        <span class="goods-price-icon">￥</span>{{ goods.price }}
      </div>
      <div class="goods-title">{{ goods.name }}</div>
      <div class="goods-des">{{ goods.description }}</div>
    </div>
    <!-- <div class="detail-center">
        <span class="detail-center-txt">服务</span>
        <span class="detail-center-text">联系客服</span>
        <van-icon class="detail-center-more" name="arrow" />
    </div> -->
    <div class="detail-content">
      <div class="detail-content-title">
        <span class="detail-contnet-line"></span>
        <span class="detail-contnet-line-txt">商品详情</span>
      </div>
      <!-- <div class="detail-content-list">
            <span class="detail-content-li">颜色：银色</span>
            <span class="detail-content-li">容量：64G</span>
            <span class="detail-content-li">购买渠道：其他版本</span>
            <span class="detail-content-li">拆修情况：无拆修</span>
        </div> -->
      <!-- <div class="detail-content-info">
            苹果X ----------
            "认证iPhonex有容面质保一年苹果X iPhonex有容面质保一年苹果X iPhonex有容面质保一年
            ------手机好卖家"金牌老店
            仅换来决定离开
        </div> -->
      <div class="goods-vedio" v-if="goods.videoUrl">
        <video
          id="video"
          class="video"
          controls="controls"
          :src="goods.videoUrl"
          :poster="goods.iconUrl"
          webkit-playsinline="true"
          playsinline="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x-webkit-airplay="true"
          preload="true"
        ></video>
        <!-- <video id="video" class="video" controls="controls" :src="domain+goods.iconUrl" :poster="domain+goods.videoUrl" webkit-playsinline="true" playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x-webkit-airplay="true" preload="true"></video> -->
      </div>
      <div class="detail-goods-img">
        <img class="detail-goods-image" :src="goods.imageUrl" />
        <!-- <img class="detail-goods-image" :src="domain+goods.imageUrl" /> -->
      </div>
    </div>
    <div class="detail_footer">
      <div class="detail_footer_price_box">
        <span class="detail_footer_price">共计：￥{{ total.totalPrice }}</span>
        <span class="detail_footer_price" v-if="total.couponPrice"
          >券后价：￥{{ total.afterCouponPrice }}</span
        >
      </div>
      <!-- <div class="numBlock">
            <div class="minus" @click="clickMinus">
                <van-icon name="minus" />
            </div>
            <div class="num">{{num}}</div>
            <div class="add" @click="clickAdd">
                <van-icon name="plus" />
            </div>
        </div> -->
      <span class="detail_footer_btn" @click="submitOrder">立即购买</span>
    </div>
    <div class="mask" v-if="showMask">
      <img
        class="loading"
        src="https://p3.lefile.cn/product/adminweb/2020/09/25/b831808f-deac-4526-aa16-b3e1a3e85b0a.gif"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";
import https from "@/api/https";
import wx from "weixin-js-sdk";
Vue.use(Lazyload);
let _that;
export default {
  name: "Detail",
  components: {},
  data() {
    return {
      domain: "https://www.taiyiai11.com",
      options: {},
      vendingMachineId: "",
      vendingMachineDoorId: "",
      goods: {},
      total: {},
      num: 1,
      showMask: false,
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    // ...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    _that = this;
    if (document.cookie.indexOf("userId") == -1) {
      this.userId = localStorage.getItem("userId");
    } else {
      this.userId = this.getCookie("userId");
    }
    if (!this.getQueryString()) {
      alert("商品信息请求有误！");
    } else {
      this.getDetailData();
    }
  },
  // 方法集合
  methods: {
    getQueryString() {
      let _href = window.location.href.split("#/")[1];
      if (_href.indexOf("?") != -1) {
        let _arr = _href.split("?")[1].split("&");
        let options = {};
        for (const item in _arr) {
          options[_arr[item].split("=")[0]] = _arr[item].split("=")[1];
        }
        this.options = options;
        return true;
      } else {
        return false;
      }
    },
    isWeiXin() {
      //判断是否微信平台
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    getDetailData() {
      let _data = this.options;
      console.log(_data);
      https
        .fetchGet("/backend/product/get", _data)
        .then((res) => {
          this.vendingMachine = res.data.data;
          this.vendingMachineId = res.data.data.vendingMachineId;
          this.vendingMachineDoorId = res.data.data.vendingMachineDoorId;
          this.goods = res.data.data.product;
          this.goodscart();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clickMinus() {
      if (this.num <= 1) {
        alert("数量已经最小啦，不能再减了~");
      } else {
        this.num -= 1;
        this.goodscart();
      }
    },
    clickAdd() {
      if (this.num == 1) {
        alert("暂不支持购买多件商品，请分批购买~");
        return;
      }
      this.num += 1;
      this.goodscart();
      console.log(this.num);
    },
    goodscart() {
      https
        .fetchGet("/backend/product/user_price_detail", {
          userId: this.userId,
          productId: this.options.productId,
          quantity: this.num,
        })
        .then((res) => {
          if (res.data.status == 200) {
            _that.total = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitOrder() {
      // 判断浏览器环境
      if (!this.isWeiXin()) {
        alert("请在微信浏览器中打开~");
        return;
      }
      let _data = {
        userId: this.userId,
        vendingMachineId: this.options.vendingMachineId,
        vendingMachineDoorId: this.options.vendingMachineDoorId,
        productId: this.options.productId,
        quantity: this.num,
      };
      https
        .fetchGet("/backend/order/create_and_pay", _data)
        .then((res) => {
          this.showMask = true;
          let _data = res.data.data;
          let orderId = res.data.data.orderId;
          this.orderId = res.data.data.orderId;
          localStorage.setItem("orderId", orderId);
          // alert("即将跳转微信支付");
          //函数为分装过得 (就是调用微信支付)
          this.wexinPay({
            appId: _data.appId,
            nonceStr: _data.nonceStr,
            package: _data.package,
            paySign: _data.paySign,
            signType: _data.signType,
            timeStamp: _data.timeStamp,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setIntervalPayResult() {
      let count = 0;
      let _result = false;
      let timer = setInterval(function () {
        if (count >= 5) {
          clearInterval(timer);
          _that.showMask = false;
          _that.$router.push({ path: "/payment" });
          return;
        }
        https
          .fetchGet("/backend/order/pay_result", { orderId: _that.orderId })
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              if (res.data.data.success) {
                clearInterval(timer);
                _that.showMask = false;
                // _that.$router.push({path:'/payment'});
              }
            }
          })
          .catch((err) => {
            console.log(err);
            _that.showPayResult(_result);
            clearInterval(timer);
          });
        count++;
      }, 500);
    },
    wexinPay(data) {
      //获取后台传入的数据
      let appId = data.appId;
      let timestamp = data.timeStamp;
      let nonceStr = data.nonceStr;
      let signature = data.signature;
      let packages = data.package;
      let paySign = data.paySign;
      let signType = data.signType;
      //下面要发起微信支付了
      wx.config({
        // beta: true,
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      wx.ready(function () {
        wx.chooseWXPay({
          timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
          signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: paySign, // 支付签名
          success: function (res) {
            // 支付成功后的回调函数
            console.log(res);
            // 前端判断返回方式，微信团队郑重提示：不保证绝对可靠，切记！
            if (res.errMsg == "chooseWXPay:ok") {
              _that.setIntervalPayResult();
            } else if (res.errMsg == "chooseWXPay:cancel") {
              _that.showMask = false;
              alert("取消支付!");
              // setTimeout(function() {
              //     this.showMask = false;
              //     _that.$router.push({path:'/payment'});
              // }, 500)
            } else {
              _that.showMask = false;
              alert("订单支付失败~");
              // setTimeout(function() {
              //     _that.showMask = false;
              //     _that.$router.push({path:'/payment'});
              // }, 1000)
            }
          },
          cancel() {
            _that.showMask = false;
            alert("取消支付~");
            // setTimeout(function() {
            //     this.showMask = false;
            //     _that.$router.push({path:'/payment'});
            // }, 500)
          },
          fail() {
            //失败回调函数
            _that.showMask = false;
            alert("订单支付失败！");
            // setTimeout(function() {
            //     this.showMask = false;
            //     _that.$router.push({path:'/payment'});
            // }, 500)
          },
        });
      });
      wx.error(function (res) {
        this.showMask = false;
        console.log(res);
        alert("订单支付失败，请联系客服！");
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    },
    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i].trim();
          // 判断这个cookie的参数名是不是我们想要的
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="less" scoped>
.detail-pages {
  height: 100%;
  position: relative;
  // background: #f7f7f7;
  background-image: url("~@/assets/background.png");
  .detail-top {
    padding: 16px 15px 10px;
    margin-bottom: 6px;
    background: rgba(255, 255, 255, 0.1); //顶栏背景
    .user-info {
      line-height: 28px;
      margin-bottom: 10px;
      .user-img {
        vertical-align: middle;
        width: 68px;
        height: 68px;
        margin-right: 8px;
        border-radius: 50%;
      }
      .user-name {
        font-size: 12px;
        color: #323232;
      }
    }
    .goods-price {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 16px;
      color: #f00;
      .goods-price-icon {
        font-size: 10px;
      }
    }
    .goods-title {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 14px;
      color: #323232;
    }
    .goods-des {
      margin-bottom: 8px;
      line-height: 16px;
      font-size: 10px;
      color: #999;
    }
  }
  .detail-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 6px;
    background: rgba(255, 255, 255, 0.6);
    line-height: 32px;
    font-size: 12px;
    color: #666;
    .detail-center-txt {
      width: 50px;
    }
    .detail-center-text {
      flex: 1;
    }
  }
  .detail-content {
    padding: 10px 15px 60px;
    background: rgba(255, 255, 255, 0.2); //详情背景
    .detail-content-title {
      margin-bottom: 4px;
      .detail-contnet-line {
        display: inline-block;
        vertical-align: top;
        width: 15px;
        height: 16px;
        margin-right: 6px;
        background: rgba(255, 255, 255, 0.6);
        background-image: url("~@/assets/detail.png");
        background-size: 15px 16px;
      }
      .detail-contnet-line-txt {
        display: inline-block;
        vertical-align: top;
        font-weight: bold;
        font-size: 12px;
        color: #323232;
      }
    }
    // 商品详情型号
    // .detail-content-list {
    //   overflow-y: hidden;
    //   margin-bottom: 8px;
    //   .detail-content-li {
    //     display: inline-block;
    //     vertical-align: middle;
    //     width: 50%;
    //     line-height: 20px;
    //     font-size: 12px;
    //     color: #999;
    //   }
    // }
    .detail-content-info {
      line-height: 18px;
      font-size: 12px;
    }
    .goods-vedio {
      .video {
        width: 100%;
      }
    }
    .detail-goods-img {
      .detail-goods-image {
        width: 100%;
      }
    }
  }
  .detail_footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 52px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
    .detail_footer_price_box {
      display: flex;
      flex-direction: column;
      .detail_footer_price {
        flex: 1;
        font-weight: bold;
        font-size: 16px;
        color: #f00;
      }
    }
    .detail_footer_btn {
      height: 32px;
      line-height: 32px;
      padding: 0 15px;
      margin-left: 20px;
      box-sizing: border-box;
      // background: #f00;
      background-image: url("~@/assets/buynow.png");
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
    }
    .numBlock {
      display: flex;
      width: 86px;
      height: 26px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      box-sizing: border-box;
      line-height: 25px;
      text-align: center;
      font-family: PingFang-SC-Bold;
      font-size: 12px;
      font-weight: bold;
      color: #454545;
      .num {
        flex: 1;
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
      }
      .minus {
        position: relative;
        width: 26px;
      }
      .add {
        position: relative;
        width: 26px;
      }
    }
  }
  .mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    .loading {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 160px;
      height: 120px;
    }
  }
}
</style>
