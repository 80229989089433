import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Detail from "../views/detail.vue";
import Payment from "../views/Payment.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/mine",
    name: "Mine",
    component: () => import("../views/Mine.vue"),
  },
  {
    path: "/detail",
    name: "Detail",
    component: Detail,
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
